import { ReactComponent as Icon1 } from "../../../assets/category-general.svg";
import { ReactComponent as Icon2 } from "../../../assets/category-app.svg";
import { ReactComponent as Icon3 } from "../../../assets/category-privacy.svg";
import { ReactComponent as Icon4 } from "../../../assets/category-green.svg";
import { ReactComponent as Icon5 } from "../../../assets/category-invoice.svg";
import { ReactComponent as Icon6 } from "../../../assets/category-tariff.svg";
import { ReactComponent as Icon7 } from "../../../assets/category-meter.svg";
import { ReactComponent as Icon8 } from "../../../assets/category-contract.svg";
import { ReactComponent as Icon9 } from "../../../assets/category-change.svg";

export const CategoriesData = [
  Icon1,
  Icon2,
  Icon3,
  Icon4,
  Icon5,
  Icon6,
  Icon7,
  Icon8,
  Icon9,
];
