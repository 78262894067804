import React, { ReactElement } from "react";

import Footer from "../../Footer";
import NavBar from "../../NavBar";
import SearchBlock from "../Search/SearchBlock";
import { useCategories } from "../hooks/useCategories";
import Preloader from "../../SVG/Preloader";
import { PreloaderContainerLayout } from "../../Contract/Layout/styled";
import Categories from "./Categories";
import { navigate } from "@reach/router";

const Index = (): ReactElement => {
  const categories = useCategories();
  const onHandleSearch = (searchValue: string) => {
    navigate(`/faq-search/?value=${searchValue}`);
  };
  return categories.isPending ? (
    <PreloaderContainerLayout>
      <Preloader />
    </PreloaderContainerLayout>
  ) : (
    <div>
      <NavBar />
      <div className="container">
        <div className="row"></div>
      </div>
      <SearchBlock defaultValue={""} onHandleSearch={onHandleSearch} />
      <Categories categoriesData={categories.data || []} />
      <Footer isFaqPage={true} />
    </div>
  );
};

export default Index;
