import React, { ReactElement } from "react";

import { CategoriesContainer, CategoriesCards } from "./styled";
import { CategoryFAQ } from "../../../types";
import Category from "./CategoryCard";
import { CategoriesData } from "./CategoriesData";

interface CategoriesProps {
  categoriesData: Array<CategoryFAQ>;
}

const Categories = ({ categoriesData }: CategoriesProps): ReactElement => {
  return (
    <CategoriesContainer>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <CategoriesCards>
              {categoriesData.map((category, index) => (
                <Category
                  key={category.id}
                  category={category}
                  icon={CategoriesData[index]}
                  questionsNumber={
                    category?.answers?.filter((answer) => answer.status === 2)
                      .length || 0
                  }
                />
              ))}
            </CategoriesCards>
          </div>
        </div>
      </div>
    </CategoriesContainer>
  );
};

export default Categories;
