import styled from "styled-components";

export const CategoriesContainer = styled.div`
  background-color: #f6f9fa;
  padding-bottom: 70px;
  @media (max-width: 1130px) {
    padding-bottom: 30px;
  }
  @media (max-width: 767px) {
    background-color: #ffffff;
  }
`;

export const CategoriesCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 74px;
  @media (max-width: 980px) {
    width: 600px;
    margin: 30px auto 0 auto;
  }
  @media (max-width: 767px) {
    width: 100%;
    margin: 15px auto 0 auto;
    justify-content: center;
  }
`;

export const CategoryCard = styled.div`
  width: 360px;
  min-height: 337px;
  border-radius: 18px;
  box-shadow: 0 0 26px 0 rgba(10, 31, 68, 0.12);
  background-color: #ffffff;
  padding: 32px;
  cursor: pointer;
  margin-bottom: 30px;
  position: relative;
  @media (max-width: 1130px) {
    width: 285px;
    height: 365px;
  }
  @media (max-width: 767px) {
    max-width: 345px;
    width: 100%;
    height: 337px;
    margin-bottom: 15px;
  }
`;

export const CategoryName = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #263242;
  margin-top: 32px;
  margin-bottom: 15px;
`;

export const CategoryDescription = styled.div`
  font-size: 16px;
  line-height: 1.31;
  letter-spacing: normal;
  color: #838383;
  margin-bottom: 31px;
`;

export const IconContainer = styled.div`
  width: 80px;
  height: 80px;
`;

export const QuestionsNumber = styled.div`
  font-size: 17px;
  font-weight: bold;
  color: #4646eb;
  margin-right: 12px;
`;

export const QuestionsContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: 33px;
`;

export const ArrowContainer = styled.div`
  margin-left: 0;
  transition: margin 1s;
  ${CategoryCard}:hover & {
    margin-left: 24px;
  }
`;
