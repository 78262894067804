import React, { ReactElement } from "react";
import { navigate } from "@reach/router";

import {
  CategoryCard,
  IconContainer,
  CategoryName,
  CategoryDescription,
  QuestionsNumber,
  QuestionsContainer,
  ArrowContainer,
} from "./styled";
import { CategoryFAQ, SVGReactComponent } from "../../../types";
import { ReactComponent as ArrowIcon } from "../../../assets/arrow-left.svg";

interface CategoryProps {
  category: CategoryFAQ;
  icon: SVGReactComponent;
  questionsNumber: number;
}

const getIconComponent = (Icon: SVGReactComponent) => {
  return (
    <Icon
      style={{ width: "100%", height: "100%" }}
      preserveAspectRatio="xMinYMin meet"
      viewBox="0 0 80 80"
    />
  );
};

const Category = ({
  category,
  icon,
  questionsNumber,
}: CategoryProps): ReactElement => {
  const onHandleClick = (id: number) => {
    navigate(`/faq-category/${id}`);
  };
  return (
    <CategoryCard
      onClick={() => {
        onHandleClick(category.id);
      }}
    >
      <IconContainer>{getIconComponent(icon)}</IconContainer>
      <CategoryName>{category.name}</CategoryName>
      <CategoryDescription>{category.description}</CategoryDescription>
      <QuestionsContainer>
        <QuestionsNumber>{questionsNumber} Antworten</QuestionsNumber>
        <ArrowContainer>
          <ArrowIcon
            style={{ width: "24px", height: "100%" }}
            preserveAspectRatio="xMinYMin meet"
            viewBox="0 0 32 32"
          />
        </ArrowContainer>
      </QuestionsContainer>
    </CategoryCard>
  );
};

export default Category;
