import React, { ReactElement } from "react";

import SEO from "../components/Seo";
import Index from "../components/FAQ/Categories";

const FAQ = (): ReactElement => {
  return (
    <div>
      <SEO title="FAQ" />
      <Index />
    </div>
  );
};

export default FAQ;
